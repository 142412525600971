<template>
    
    <article class="recurso-el">
        <h3 class="status" :open="data.can_sub_public">Inscripción {{(data.can_sub_public) ? 'abierta' : 'cerrada'}}</h3>

        <h2>{{data.name}}</h2>
        <p>Desde: {{data.start_date | parsedate}} - Hasta: {{data.end_date | parsedate}}</p>
        <a v-if="data.can_sub" :loading="loading" @click="requestInscripcion" class="btn -skull-orange">INSCRIBIRME AL PROGRAMA</a>
            <p class="reason" v-if="data.close_reason == 3">Ya estás inscrito/a a este programa</p>
        <router-link :to="$t('links.programas')+'/'+data.slug" class="enlace-cover"></router-link>
    </article>

    

</template>


<script>

    import moment from 'moment';

    import { mapActions} from 'vuex';

    export default {
        name: 'programa',
        props: ['data'],
        data : () => ({
            loading : false
        }),
        filters: {
            parsedate(value) {
                return moment(value).format("DD/MM/YYYY")
            }
        },
        methods : {
            ...mapActions(['inscripcionPrograma']),
            requestInscripcion(){
                this.loading = true;
                this.inscripcionPrograma(this.data.id).then( r => {
                    if(r){
                        this.data.can_sub = false;
                        this.data.close_reason = 3;
                        this.loading = false;
                    }
                });
            }
        }
    }
</script>