<template>
  <div id="app" :type="usertype" :route="$route.name">
    <app-header v-if="!$route.meta.hideHeader && islogged"></app-header>
    <router-view />
    <alert></alert>
    <app-footer></app-footer>

    <template v-if="islogged">
      <popup-programas v-if="ownprogramas && ownprogramas.length == 0 && !showmensaje"></popup-programas>
    </template>

  </div>
</template>

<script>

  import alert from '@/components/alert';
  import appheader from '@/components/app-header';
  import appfooter from '@/components/app-footer';
  import popupprogramas from '@/components/mi-perfil/popup-programas';

  import {mapActions,mapGetters} from 'vuex';

  export default {
    name : 'app',
    mounted(){
      setTimeout(() =>{
        if(this.islogged){
          this.initApp();
        }
        this.requestDomains();
      },300);
    },
    data: () => ({
            hideProfesionalin : ['mi-perfil','completar-cuenta']
    }),
    methods : {
      ...mapActions([
        'initApp',
        'requestDomains'
      ])
    },
    computed:{
      ...mapGetters({
        islogged : 'getLogged',
        usertype : 'getUserType',
        ownprogramas : 'getOwnProgramas',
        showmensaje : 'getshowmensaje'
      }),
    },
    components : {
      'alert' : alert,
      'app-header' : appheader,
      'app-footer' : appfooter,
      'popup-programas' : popupprogramas
    }
  }
</script>

<style lang="scss">
  

  @import '@/assets/css/colors.scss';
  @import '@/assets/css/style.scss';
  @import '@/assets/css/responsive.scss';
  @import '@/assets/css/font-awesome.scss';
  @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');
  @import url('https://unpkg.com/vue-multiselect@2.1.6/dist/vue-multiselect.min.css');
  
</style>