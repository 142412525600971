<template>
    <div class="year-datepicker">
        <datepicker v-model="data" name="name" :language="es" :full-month-name="true" :monday-first="true"
            :format="'yyyy'" :id="name" :minimum-view="'year'" :openDate="data || new Date()" :fill="!!data"
            @input="emit">
        </datepicker>
        <label :for="name">{{label}}</label>
    </div>
</template>

<script>


    import { es } from 'vuejs-datepicker/dist/locale'
    import Datepicker from 'vuejs-datepicker';

    import moment from 'moment';
    moment.locale('es')

    export default {
        name: 'year-datepicker',
        props: ['name', 'label','value'],
        mounted() {
            if(this.value != null && this.value != ""){
                this.data = new Date(this.value);
            }
        },
        data: () => ({
            data: null,
            es: es
        }),
        components: {
            Datepicker
        },
        methods: {
            emit() {
                this.$emit('input', moment(this.data).format('YYYY-MM-DD'));
            }
        },
        watch : {
            'value'(){
                this.data = new Date(this.value);
            }
        }

    }
</script>