<template>
  <div class="wrapper home row-center">
      <home-mentor v-if="usertype == 'mentor'"></home-mentor>
      <home-mentees v-if="usertype == 'mentee'"></home-mentees>
  </div>
</template>

<script>


  import homementor from '@/components/home/home-mentor';
  import homementees from '@/components/home/home-mentees';
  

  import {mapGetters} from 'vuex';

  export default {
    name: 'Home',
    computed : {
      ...mapGetters({
        usertype : 'getUserType'
      })
    },
    components :{
        'home-mentor' : homementor,
        'home-mentees' : homementees
    }
  }
</script>