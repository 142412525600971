import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import store from '@/store/index.js';

import translations from '@/translations/index.js';

const links = translations.es.links;

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/gracias',
    name: 'gracias',
    component: () => import(/* webpackChunkName: "gracias" */ '../views/gracias.vue'),
    meta: {
      requireAuth: false
    }
  },
  {
    path: `${links.login}/:id?/:paso?`,
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue'),
    meta: {
      hideHeader: true
    }
  },
  {
    path: `${links.recuperarpass}/:id?`,
    name: 'login',
    component: () => import(/* webpackChunkName: "reset-password" */ '../views/recuperar-pass.vue'),
    meta: {
      hideHeader: true
    }
  },
  {
    path: `${links.registro}/:id?`,
    name: 'registro',
    component: () => import(/* webpackChunkName: "about" */ '../views/registro.vue'),
    meta: {
      hideHeader: true
    }
  },
  {
    path: links.mismentorias,
    name: 'mis-mentorias',
    component: () => import(/* webpackChunkName: "about" */ '../views/mis-mentorias.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: links.recursos,
    name: 'recursos-y-eventos',
    component: () => import(/* webpackChunkName: "about" */ '../views/recursos-y-eventos.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: `${links.recursos}/:id?`,
    name: 'ficha-recursos-y-eventos',
    component: () => import(/* webpackChunkName: "ficha-recursos" */ '../views/ficha-recurso.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: `${links.contacto}/:type?/:id?`,
    name: 'contacto',
    component: () => import(/* webpackChunkName: "about" */ '../views/contacto.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: links.miperfil,
    name: 'mi-perfil',
    component: () => import(/* webpackChunkName: "about" */ '../views/mi-perfil.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: links.completarcuenta,
    name: 'completar-cuenta',
    component: () => import(/* webpackChunkName: "completar-cuenta" */ '../views/completar-cuenta.vue'),
    meta: {
      requireAuth: false,
      hideHeader: true
    }
  },
  {
    path: links.programas,
    name: 'programas',
    component: () => import(/* webpackChunkName: "about" */ '../views/programas.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: `${links.programas}/:id?`,
    name: 'ficha-programas-y-eventos',
    component: () => import(/* webpackChunkName: "ficha-programa" */ '../views/ficha-programa.vue'),
    meta: {
      // requireAuth: true
    }
  },
  {
    path: `${links.fichamentor}/:id?`,
    alias: `${links.fichamentee}/:id?`,
    name: 'ficha-mentor',
    component: () => import(/* webpackChunkName: "ficha-mentor" */ '../views/ficha-mentor.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: `${links.valorarmentoria}/:id?`,
    name: 'valorar-mentoria',
    component: () => import(/* webpackChunkName: "valorar-mentoria" */ '../views/valoracion.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: links.redmentores,
    name: 'red-de-mentores',
    component: () => import(/* webpackChunkName: "red-de-mentores" */ '../views/red-de-mentores.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: links.encuentramentor,
    name: 'encuentra-mentor',
    component: () => import(/* webpackChunkName: "encuentra-mentor" */ '../views/encuentra-mentor.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: links.avisolegal,
    name: 'aviso-legal',
    component: () => import(/* webpackChunkName: "aviso-legal" */ '../views/aviso-legal.vue'),
    meta: {
      requireAuth: false
    }
  },
  {
    path: links.politicacookies,
    name: 'politica-cookies',
    component: () => import(/* webpackChunkName: "politica-cookies" */ '../views/politica-cookies.vue'),
    meta: {
      requireAuth: false
    }
  },
  {
    path: links.logout,
    name: 'logout',
    component: () => import(/* webpackChunkName: "cerrar-sesion" */ '../views/cerrar-sesion.vue'),
    meta: {
      requireAuth: true
    }
  },
  {
    path: `${links.accesosaml}/:id?`,
    name: 'accesosaml',
    component: () => import(/* webpackChunkName: "cerrar-sesion" */ '../views/acceso-saml.vue'),
    meta: {
      requireAuth: false
    }
  },
  {
    path: `${links.registersaml}/:id?`,
    name: 'registersaml',
    component: () => import(/* webpackChunkName: "cerrar-sesion" */ '../views/registro-saml.vue'),
    meta: {
      requireAuth: false,
      hideHeader : true
    }
  },
  {
    path: `${links.validarcuenta}/:id?`,
    name: 'validarcuenta',
    component: () => import(/* webpackChunkName: "validar-cuenta" */ '../views/validar-cuenta.vue'),
    meta: {
      requireAuth: false
    }
  },
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})


router.beforeEach((to, from, next) => {
  //COMPRUEBO INICIO DE SESIÓN Y SI ESTÁ LOGADO

  if (to.matched.some(route => route.meta.requireAuth) && !store.getters.getLogged) {
    window.sessionStorage.setItem('goto',to.fullPath);
    next({ name: 'login' })
    return
  }

  if(to.name == 'login' && store.getters.getLogged){
    next({ name: 'home' });
    return;
  }

  next()
});

export default router;

