<template>

    <button class="editar" @click="$emit('click')">

        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 23">
            <defs>
                <filter id="60mzyw8u1a">
                    <feColorMatrix in="SourceGraphic"
                        values="0 0 0 0 0.631373 0 0 0 0 0.631373 0 0 0 0 0.631373 0 0 0 1.000000 0" />
                </filter>
            </defs>
            <g fill="none" fill-rule="evenodd" stroke-linejoin="round">
                <g filter="" transform="translate(-1170 -666)">
                    <g>
                        <g :fill="(!edit) ? '#d3d3d3' : '#EA523A'" fill-rule="nonzero" :stroke="(!edit) ? '#d3d3d3' : '#EA523A'" stroke-width=".2">
                            <path
                                d="M22.321 2.762L20.238.679c-.904-.904-2.483-.906-3.391 0L2.059 15.467c-.058.057-.1.128-.122.207L.02 22.389c-.048.168-.002.348.121.47.092.092.214.141.34.141.044 0 .088-.006.132-.018l6.715-1.919c.078-.022.15-.064.208-.122L22.32 6.155c.453-.454.702-1.056.702-1.696 0-.641-.249-1.243-.702-1.697zm-.678 2.714l-14.7 14.699-5.766 1.647 1.649-5.765 14.699-14.7c.544-.544 1.492-.543 2.034 0l2.084 2.083c.561.561.561 1.474 0 2.036z"
                                transform="translate(1170 666)" />
                            <path
                                d="M22.321 2.762L20.238.679c-.904-.904-2.483-.906-3.391 0L2.06 15.467c-.187.187-.187.49 0 .678.187.187.491.187.678 0L17.525 1.357c.544-.544 1.492-.543 2.034 0l2.084 2.083c.561.561.561 1.474 0 2.036L6.856 20.263c-.188.187-.188.491 0 .678.094.093.216.14.34.14.122 0 .245-.047.339-.14L22.32 6.154c.936-.935.936-2.457 0-3.392z"
                                transform="translate(1170 666)" />
                            <path
                                d="M7.534 20.263l-4.797-4.796c-.187-.187-.49-.187-.678 0-.187.187-.187.49 0 .678l4.797 4.796c.094.093.216.14.34.14.122 0 .245-.047.338-.14.187-.187.187-.49 0-.678zM20.005 2.996c-.188-.187-.492-.187-.679 0l-13.43 13.43c-.187.187-.187.491 0 .678.094.093.217.14.34.14.123 0 .245-.047.338-.14l13.43-13.43c.188-.187.188-.491 0-.678z"
                                transform="translate(1170 666)" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>

    </button>


</template>

<script>
    export default {
        name: 'editar',
        props: ['edit']
    }
</script>