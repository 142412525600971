<template>

    <header class="menu" :min="minmenu">

        <div class="container row-between">

            <router-link to="/" class="logo-container">
                <img class="logo" src="/img/logo-uc3m-alumni.png" alt="Uc3m Alumni">
            </router-link>



            <nav class="row-end" :show="showmenu">
                <a @click="showmenu = false" class="close">✕</a>
                <router-link to="/">Home</router-link>
                <router-link :to="$t('links.miperfil')">Mi perfil</router-link>
                <router-link :to="$t('links.programas')">Programas</router-link>
                <router-link :to="$t('links.mismentorias')">Mis mentorías</router-link>
                <router-link :to="$t('links.recursos')">Recursos y eventos</router-link>
                <router-link :to="$t('links.redmentores')" v-if="usertype == 'mentor'">Red de Mentores</router-link>
                <router-link :to="$t('links.encuentramentor')" v-if="usertype == 'mentee'">Encuentra un mentor/a</router-link>
                <router-link :to="$t('links.contacto')">Contacto</router-link>
                <router-link :to="$t('links.logout')" class="mvl">Cerrar sesión</router-link>
            </nav>

            <button class="show-menu" @click="showmenu = true">
                <span></span>
                <span></span>
                <span></span>
            </button>


            <div class="areas">

                <div class="mi-perfil-container">
                    <button @click="showmenuperfil = true">{{name}}</button>

                    <div class="submenu-perfil" @mouseleave="showmenuperfil = false" v-if="showmenuperfil">
                        <router-link :to="$t('links.miperfil')">
                            <div class="contenedor-imagen">
                                <div class="imagen" :style="`background:url(${user.image_url}) center/cover no-repeat`"></div>
                            </div>
                            <strong>{{name}}</strong>
                            <span>Ver tu perfil</span>
                        </router-link>

                        <!-- <a href="">
                            <div class="contenedor-imagen">
                                
                            </div>
                            <strong>Cambiar de cuenta</strong>
                            <span>Iniciar sesión como mentee</span>
                        </a> -->

                        <router-link :to="$t('links.logout')">
                            <div class="contenedor-imagen">
                                <img src="/img/logout.svg" alt="Cerrar sesión">
                            </div>
                            <strong>Salir</strong>
                        </router-link>
                    </div>

                </div>

            </div>




        </div>

    </header>

</template>


<script>

    import {mapGetters} from 'vuex'

    export default {
        name: 'app-header',
        mounted() {
            window.addEventListener('scroll', () => {
                window.requestAnimationFrame(() => {
                    this.checkmenu();
                });
            });
        },
        data() {
            return {
                minmenu: false,
                showmenu: false,
                showmenuperfil : false
            }
        },
        methods: {
            checkmenu() {
                let pos = window.scrollY;
                this.minmenu = (pos >= 130);
            }
        },
        computed : {
            ...mapGetters({
                name : 'getName',
                user : 'getuser',
                usertype : 'getUserType'
            })
        },
        watch: {
            '$route'() {
                this.showmenu = false
            }
        }
    }
</script>