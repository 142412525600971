<template>
    <div class="container resumen-mentorias">
        <section class="resumen-mentoria -curso">
            <header>
                <span>{{mentorias[1].length}}</span>
                <h2>Mentorías en curso</h2>
                <button class="show-btn" @click="showencurso = !showencurso">{{(showencurso) ? '-' : '+'}}</button>
            </header>

            <main v-show="showencurso">
                <template v-if="mentorias[1].length != 0">
                    <article class="mentoria-resumen" :key="key" v-for="(mentoria,key) in mentorias[1]">
                        <h2 v-if="usertype == 'mentee'">Mentor: {{mentoria.profesor.name}}</h2>
                        <h2 v-if="usertype == 'mentor'">Mentee: {{mentoria.mentee.name}}</h2>
                        <h3>{{mentoria.program.name}}</h3>
                        <p v-if="mentoria.start_date && mentoria.end_date">Desde: {{mentoria.start_date | parsedate}} - Hasta: {{mentoria.end_date | parsedate}}</p>
                        
                    </article>
                </template>
                <p v-else>No hay mentorias en curso</p>
            </main>

        </section>

        <section class="resumen-mentoria -espera">
            <header>
                <span>{{mentorias[0].length}}</span>
                <h2>Mentorías en espera</h2>
                <button class="show-btn" @click="showenespera = !showenespera">{{(showenespera) ? '-' : '+'}}</button>
            </header>
            <main v-show="showenespera">
                <template v-if="mentorias[0].length != 0">
                    <article class="mentoria-resumen" :key="key" v-for="(mentoria,key) in mentorias[0]">
                        <h2 v-if="usertype == 'mentee'">Mentor: {{mentoria.profesor.name}}</h2>
                        <h2 v-if="usertype == 'mentor'">Mentee: {{mentoria.mentee.name}}</h2>
                        <h3>{{mentoria.program.name}}</h3>
                    </article>
                </template>
                <p v-else>No hay mentorías en espera</p>
            </main>
        </section>

        <section class="resumen-mentoria -finalizadas">
            <header>
                <span>{{mentorias[2].length}}</span>
                <h2>Mentorías finalizadas</h2>
                <button class="show-btn" @click="showfinalizadas = !showfinalizadas">{{(showfinalizadas) ? '-' :
                    '+'}}</button>
            </header>
            <main v-show="showfinalizadas">
                <template v-if="mentorias[2].length != 0">
                    <article class="mentoria-resumen" :key="key" v-for="(mentoria,key) in mentorias[2]" :status="mentoria.status">
                        <h2 v-if="usertype == 'mentee'">Mentor: {{mentoria.profesor.name}}</h2>
                        <h2 v-if="usertype == 'mentor'">Mentee: {{mentoria.mentee.name}}</h2>
                        <h3>{{mentoria.program.name}}</h3>
                        <p v-if="mentoria.start_date && mentoria.end_date">Desde: {{mentoria.start_date | parsedate}} - Hasta: {{mentoria.end_date | parsedate}}</p>
                    </article>
                </template>
                <p v-else>No hay mentorías finalizadas</p>
            </main>
        </section>
        <router-link class="link-arrow" :to="$t('links.mismentorias')" v-if="fullmentorias.length != 0">Ver todas</router-link>
    </div>


</template>


<script>

    import {mapGetters} from 'vuex';

    import moment from 'moment'

    export default {
        name: 'resumen-mentorias',
        data: () => ({
            showencurso: false,
            showenespera: false,
            showfinalizadas: false,
        }),
        computed : {
            ...mapGetters({
                mentorias : 'getMentoriasStatus',
                fullmentorias : 'getMentorias',
                usertype : 'getUserType',
            })
        },
        filters : {
            parsedate(value) {
                return moment(value).format("DD/MM/YYYY")
            }
        }
    }
</script>