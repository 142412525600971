import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

import axios from 'axios';



const busquedamentores = {
    state : {
        queriesstats : {}
    },
    getters : {
        getquerieStats : state => state.queriesstats
    },
    actions: {
        requestMentores({ commit,getters }, filtro) {
            let clean = ['area', 'sector','programas','language'];
            let data = {
                columns: [],
                lenght : 1000
            }
            if (filtro) {
                data.textFilter = filtro.textFilter;
                let sugeridos = filtro.sugeridos;

                delete filtro.sugeridos;
                delete filtro.textFilter;

                clean.forEach(e => {
                    filtro[e].search = filtro[e].search.map(item => item.id)
                })

                if(sugeridos){
                    let areas = getters.getuser.area.map(item => item.id);

                    filtro.areas = {
                        name: 'area.name',
                        search: areas,
                        objectFilter: true
                    };
                }
                
                data.columns = Object.values(filtro);
            }

            return new Promise((resolve, reject) => {
                axios.post('api/profesor/list', data).then(result => {
                    resolve(result.data);
                    commit('null');

                }).catch(error => {
                    reject(error);
                });
            });
        },
        savebusquedamentores({ dispatch,getters}, querie) {
            
            let busquedas = getters.getuser.queries || [];
            
            busquedas.push(querie);

            let data = {
                queries : busquedas
            }
            
            dispatch('requestChangeUser',data);

        },
        requestErase({ dispatch,getters }, busqueda) {
            let busquedas = getters.getuser.queries;
            
            busquedas = busquedas.filter(b => b.name != busqueda.name);

            let data = {
                queries : busquedas
            }
            
            dispatch('requestChangeUser',data);

        },
        checkQueries({getters,dispatch,commit}){
         
            let busquedas = getters.getuser.queries;

            let showalert = false;

            let stats = new Object();

            busquedas.forEach(b => {
                dispatch('requestMentores',JSON.parse(JSON.stringify(b.busqueda))).then(r => {
                    if(r.length != 0){
                        showalert = true;
                    }

                    stats[b.name] = r.length;

                    commit('setStats',stats);

                    if(showalert){
                        commit('setAlert', 1009);
                    }
                })
            });
            
            
        },
        requestMentor({ commit }, id) {

            return new Promise((resolve, reject) => {
                axios.get('/api/profesor/show/'+id).then(result => {
                    if (result.data) {
                        resolve(result.data);
                    } else {
                        resolve(false)
                        commit('setAlert', result.data.error);
                    }

                }).catch(error => {
                    reject(error);
                });
            });
        },
        requestMentee({ commit }, id) {

            return new Promise((resolve, reject) => {
                axios.get('/api/mentee/show/'+id).then(result => {
                    if (result.data) {
                        resolve(result.data);
                    } else {
                        resolve(false)
                        commit('setAlert', result.data.error);
                    }

                }).catch(error => {
                    reject(error);
                });
            });
        },
    },
    mutations : {
        setStats(store, stats){
            store.queriesstats = stats;
        }
    }
}

export default busquedamentores;
