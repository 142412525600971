import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

import axios from 'axios';



const recursos = {
    state: {
        programas: null,
        ownProgramas : null,
        showmensajeProgramas : false
    },
    getters: {
        getProgramas : state => state.programas,


        getOwnProgramas : state => state.ownProgramas,
        getshowmensaje : state => state.showmensajeProgramas
    },
    actions: {
        requestProgramas: ({ commit }) => {
            axios.get(`/api/programs/list`).then(response => {
                commit('setProgramas', response.data)
            });
        },
        requestOwnProgramas: ({ commit,dispatch }) => {
            axios.get(`/api/programs/get`).then(response => {
                commit('setOwnProgramas', response.data);
                dispatch('requestEntries')
            });
        },
        requestPrograma: ({ commit }, slug) => {
            return new Promise((resolve) => {
                axios.get(`/api/programs/show/${slug}`).then(response => {
                    resolve(response.data);
                    commit('null');
                });
            });
        },
        inscripcionPrograma: ({ commit,dispatch }, id) => {
            return new Promise((resolve) => {
                axios.post(`/api/programs/add/${id}`).then(response => {

                    dispatch('requestProgramas');
                    dispatch('requestOwnProgramas');

                    if(response.data.checks[0].check){
                        resolve(true);
                        commit('setAlert','1008');
                        window.localStorage.removeItem('program_id');
                    }else{
                        commit('setAlert',`adscrito-${response.data[0].reason}`);
                    }

                    
                });
            });
        },
        showVistoMensaje : ({commit}) => {
            commit('setvistomensaje');
        },
        requestRegisterInProgram: ({ commit }, id) => {
            return new Promise((resolve) => {
                axios.get(`/api/programs/register/${id}`).then(response => {
                    resolve(response.data.status);
                    commit('null');
                });
            });
        },
    },
    mutations: {
        setProgramas(state, data) {
            state.programas = data;
        },
        setOwnProgramas(state, data) {
            state.ownProgramas = data;
        },
        setvistomensaje(state){
            state.showmensajeProgramas = true;
        }
    }
}

export default recursos;
