import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

import axios from 'axios';


import router from '@/router'



const auth = {
	state: {
		logged: !!window.localStorage.getItem('_token'),
		name: '',
		usertype: '',
		user: ''
	},
	getters: {
		getLogged: state => state.logged,
		getName: state => state.name,
		getUserType: state => state.usertype,
		getuser: state => state.user
	},
	actions: {
		login: ({ commit, dispatch }, data) => {
			return new Promise((resolve, reject) => {
				axios.post(`api/${data.type}/login`, data).then(result => {

					if (result.data.status) {
						dispatch('setLogged', result.data.token)
						resolve(true);
					} else {
						commit('setAlert', String(result.data.error))
						resolve(false);
					}

				}).catch(error => {
					reject(error);
				});
			});
		},


		setLogged({ commit, dispatch }, token) {
			window.localStorage.setItem('_token', token);
			commit('setLogged');
			dispatch('requestUserInfo');
			dispatch('requestEntries')
			dispatch('requestProgramas')
			dispatch('requestOwnProgramas');
			dispatch('requestMentorias');
			dispatch('requestProfesional')

			if (window.localStorage.getItem('program_id')) {
				dispatch('inscripcionPrograma', window.localStorage.getItem('program_id'));
				window.localStorage.removeItem('program_id');
			}

			return true;




		},




		requestUserInfo: ({ commit, dispatch }) => {
			return new Promise(() => {
				axios.get(`api/general/user`).then(result => {

					commit('setUser', result.data);
					commit('setCompleteUser', result.data.object);

					if (result.data.object.status != 1) {
						switch (result.data.object.status) {
							case 0:
								commit('setAlert', 1016);
								break;


							case 3:
								commit('setAlert', 1017);
								break;
						}
					}


					if (result.data.object.queries && result.data.object.queries.length != 0) {
						dispatch('checkQueries')
					}

				}).catch(error => {
					if (error.response.status == 401) {
						commit('setLogout');
						router.push({ name: 'login' })
					}
				});

			});
		},

		requestLogout: ({ commit }, type) => {

			const TYPES = {
				'mentee': 'mentee',
				'mentor': 'profesor'
			}

			return new Promise((resolve) => {
				axios.post(`api/${TYPES[type]}/logout`).then(() => {
					commit('setLogout');
					resolve(true);
				}).catch(() => {
					commit('setLogout');
					resolve(true);
				});

			});
		},


		requestRegister: ({ commit }, data) => {

			if (window.localStorage.getItem('program_id')) {
				data.extra_program_id = window.localStorage.getItem('program_id');
			}

			return new Promise((resolve) => {
				axios.post(`api/${data.type}/register`, data).then(result => {

					window.localStorage.removeItem('program_id');

					if (result.data.status) {
						resolve(true);
						commit('setAlert', 1000)
					} else {
						resolve(false);
						commit(null)
					}
				}).catch(error => {
					resolve(false);
					if (error.response.data.status) {
						commit('setCustomAlert', error.response.data.errors);
					} else {
						commit('setAlert', 1015)
					}
				});

			});
		},


		requestRegisterSAML: ({ commit }, data) => {

			if (window.localStorage.getItem('program_id')) {
				data.extra_program_id = window.localStorage.getItem('program_id');
			}


			return new Promise((resolve, reject) => {
				axios.post(`saml/register/${data.type}`, data).then(result => {

					window.localStorage.removeItem('program_id');

					if (result.data.status) {
						resolve(result.data.token);
						commit('setAlert', 1019)
					} else {
						resolve(false);
						commit(null)
					}
				}).catch(error => {
					reject(error);
					resolve(false);
					if (error.response.data.status) {
						commit('setCustomAlert', error.response.data.errors);
					} else {
						commit('setAlert', 1015)
					}
				});

			});
		},

		requestRecoveryPass: ({ commit }, data) => {
			return new Promise((resolve, reject) => {
				axios.post(`/api/${data.type}/send_password`, data).then(result => {
					if (result.data.status) {
						resolve(true);
						commit('setAlert', 1001)
					} else {
						resolve(false);
						commit(null)
					}
				}).catch(error => {
					reject(error);
					commit('setAlert', 1018)
				});

			});
		},
		sendPass: ({ commit }, data) => {
			return new Promise((resolve, reject) => {
				axios.post(`/api/${data.type}/reset_password`, data).then(result => {
					if (result.data.status) {
						resolve(true);
						commit('setAlert', 1002)
					} else {
						resolve(false);
						commit(null)
					}
				}).catch(error => {
					reject(error);
				});

			});
		},

		rgpdGet: ({ commit }, data) => {
			return new Promise((resolve, reject) => {
				axios.post(`/api/${data.type}/rgpd/get`, data).then(result => {
					if (result.data.status) {
						commit('setUser', result.data);
						resolve(result);
					} else {
						reject(result);
						commit('setAlert', String(result.data.error))
					}
				}).catch(error => {
					reject(error);
				});

			});
		},

		rgpdSave: ({ commit }, data) => {
			return new Promise((resolve, reject) => {
				axios.post(`/api/${data.type}/rgpd/save`, data).then(result => {
					if (result.data.status) {
						if (result.data.object.status != 1) {
							switch (result.data.object.status) {
								case 0:
									commit('setAlert', 1016);
									break;
								case 3:
									commit('setAlert', 1017);
									break;
							}
						}

						if (result.data.token) {
							window.localStorage.setItem('_token', result.data.token);
							commit('setLogged', result.data.token);
						}

						resolve(true);
					} else {
						reject(result);
					}
					commit(null);
				}).catch(error => {
					reject(error);
				});

			});
		},

		uc3mUserData: ({ commit }, data) => {
			return new Promise((resolve, reject) => {
				axios.get(`/saml/fulldata/${data}`).then(result => {
					if (result.data) {
						resolve(result.data);
					} else {
						resolve(false);
						commit(null)
					}
				}).catch(error => {
					reject(error);
				});

			});
		},
	},
	mutations: {

		setLogged(state) {

			if (window.localStorage.getItem('_token')) {

				const token = window.localStorage.getItem("_token");


				axios.defaults.headers.common['authorization'] = `Bearer ${token}`;

				state.logged = true;
				state.token = token;

			}

		},
		setUser(state, data) {

			let classes = {
				"Mentee": "mentee",
				"Profesor": "mentor"
			}

			state.name = [data.object.name, data.object.surname, data.object.surname_2 ?? ""];
			state.name = state.name.join(" ");
			state.usertype = classes[data.shortClass];
			console.log(state.usertype);
			state.user = data.object;

			let multi = ['ability', 'language', 'sector', 'area'];

			multi.forEach(e => {
				let mirror = Object.entries(JSON.parse(JSON.stringify(data.object[e])));
				state.user[e] = new Array();
				mirror.forEach(v => {
					let obj = {
						id: v[0],
						text: v[1]
					};
					state.user[e].push(obj);
				})
			});
		},
		setLogout(state) {
			state.logged = false;
			window.localStorage.clear();
			window.sessionStorage.clear();
			state.alertcode = null;
		}

	}
}

export default auth;
