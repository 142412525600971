export default {
	es: {
		links: {
			login: "/login",
			logout: "/cerrar-sesion",
			registro: '/registro',
			mismentorias: '/mis-mentorias',
			recursos: '/recursos-y-eventos',
			contacto: '/contacto',
			miperfil: '/mi-perfil',
			programas: '/programas',
			redmentores: '/red-de-mentores',
			recuperarpass: '/reset-password',
			encuentramentor: '/encuentra-un-mentor',
			valorarmentoria: '/valorar-mentoria',
			validarcuenta: '/validar-cuenta',
			completarcuenta: '/completar-cuenta',
			fichamentor : '/mentor',
			fichamentee : '/mentee',
			accesosaml : '/saml/login',
			registersaml : '/saml/register',
			avisolegal : '/aviso-legal',
			politicacookies : '/politica-cookies'
		},
		alerts: {
			'0': {
				"titulo": "Aviso",
				"mensaje": "Tu email no está validado. Revisa tu correo, hemos enviado un mensaje de verificación a tu email personal."
			},
			'1': {
				"titulo": "¡Atención!",
				"mensaje": "DNI o contraseña incorrectos"
			},
			'2': {
				"titulo": "Aviso",
				"mensaje": "Tu usuario está deshabilitado. En caso de duda puedes contactar con nosotros a través del<a href='/contacto'>contacto</a><br>Gracias."
			},
			'3': {
				"titulo": "Aviso",
				"mensaje": "Tu solicitud está en proceso de revisión. En caso de duda puedes contactar con nosotros a través del <a href='/contacto'>contacto</a><br>Gracias."
			},
			'4': {
				"titulo": "Aviso",
				"mensaje": "Tu usuario está deshabilitado. En caso de duda puedes contactar con nosotros a través del<a href='/contacto'>contacto</a><br>Gracias."
			},
			'adscrito-0': {
				"titulo": "Error",
				"mensaje": "El programa está caducado"
			},
			'adscrito-1': {
				"titulo": "Error",
				"mensaje": "El programa está cancelado"
			},
			'adscrito-2': {
				"titulo": "Error",
				"mensaje": "El programa no permite adscripciones"
			},
			'adscrito-3': {
				"titulo": "Atención",
				"mensaje": "Ya estás inscrito/a al programa"
			},
			'adscrito-4': {
				"titulo": "Error",
				"mensaje": "No se puede adscribir"
			},
			'match-0' : {
				"titulo" : "Error",
				"mensaje" : 'Aviso: El número máximo de mentorías disponibles por curso académico y programa es uno'
			},
			'match-1' : {
				"titulo" : "Error",
				"mensaje" : 'No quedan mentorías disponibles para el mentor'
			},
			'match-2' : {
				"titulo" : "Error",
				"mensaje" : 'El mentee no esta en el programa correspondiente'
			},
			'match-3' : {
				"titulo" : "Error",
				"mensaje" : 'El mentor no esta en el programa correspondiente'
			},
			'match-4' : {
				"titulo" : "Error",
				"mensaje" : 'El mentee ya tiene una mentoría para este programa'
			},
			'1000': {
				'titulo': '¡Listo! Confirma tu dirección de correo electrónico',
				"mensaje": 'Revisa tu correo, hemos enviado un mensaje de verificación a tu email personal. No olvides revisar tu bandeja spam.'
			},
			'1001': {
				'titulo': 'Enhorabuena',
				"mensaje": 'Revisa tu bandeja de entrada para recuperar el acceso a tu cuenta'
			},
			'1002': {
				'titulo': 'Enhorabuena',
				"mensaje": 'Tu contraseña ha sido actualizada correctamente.'
			},
			'1003': {
				'titulo': 'Enhorabuena',
				"mensaje": 'Tu dato academico ha sido eliminado correctamente.'
			},
			'1004': {
				'titulo': 'Enhorabuena',
				"mensaje": 'Tu dato academico ha sido guardado correctamente.'
			},
			'1005': {
				'titulo': 'Enhorabuena',
				"mensaje": 'Tu dato profesional ha sido eliminado correctamente.'
			},
			'1006': {
				'titulo': 'Enhorabuena',
				"mensaje": 'Tu dato profesional ha sido guardado correctamente.'
			},
			'1007': {
				'titulo': 'Enhorabuena',
				"mensaje": 'Tus datos se han guardado correctamente.'
			},
			'1008': {
				'titulo': 'Enhorabuena',
				"mensaje": 'La inscripción al programa se ha realizado correctamente'
			},
			'1009': {
				'titulo': 'Atención',
				"mensaje": 'Al menos una de tus búsquedas contiene resultados'
			},
			'1010': {
				'titulo': 'Aviso',
				"mensaje": 'Gracias por tu confirmación. Será tu mentor/a quien reciba un email con tus datos personales para que en los próximos días inicie el contacto contigo. Por lo tanto, deberás mantenerte a la espera.'
			},
			'1011': {
				'titulo': 'Aviso',
				"mensaje": 'Esta mentoría ha sido cancelada.'
			},
			'1012': {
				'titulo': 'Error',
				"mensaje": 'El número maximo de búsquedas guardadas es 3'
			},
			'1013': {
				'titulo': 'Muchas gracias',
				"mensaje": 'Hemos recibido tu consulta, pronto nos pondremos en contacto contigo'
			},
			'1014': {
				'titulo': 'Muchas gracias',
				"mensaje": 'Hemos recibido tu valoración.'
			},
			'1015': {
				'titulo': 'Error',
				"mensaje": 'Lo sentimos ha ocurrido un error'
			},
			'1016': {
				'titulo': 'Aviso',
				"mensaje": 'Tu solicitud está en proceso de revisión. En caso de duda puedes contactar con nosotros a través del <a href="/contacto">formulario de contacto</a>.<br>Gracias',
				"logout" : "true"
			},
			'1017': {
				'titulo': 'Error',
				"mensaje": 'Tu usuario no está activo, en caso de duda puedes contactar con nosotros a través del <a href="/contacto">formulario de contacto</a>.<br>Gracias',
				"logout" : "true"
			},
			'1018': {
				'titulo': 'Error',
				"mensaje": 'Revisa los datos introducidos, no encontramos ninguna coincidencia en nuestra base de datos',
				"logout" : "true"
			},
			'1019': {
				'titulo': 'Solicitud registrada',
				"mensaje": 'En breve recibirás un email con el estado de tu solicitud.<br>No olvides revisar tu bandeja spam.<br>Gracias por tu interés.'
			},
			'1020': {
				'titulo': 'Lo sentimos',
				"mensaje": 'No se ha podido registrar su valoración. Compruebe por favor si realizó la valoración previamente'
			},
			'rgpd-0': {
				'titulo': 'RGPD ya aceptado',
				'mensaje': 'Este enlace pertenece a un usuario con el RGPD aceptado. <br> En caso de duda puedes contactar con nosotros a través del <a href="/contacto">formulario de contacto</a>.<br>Gracias',
			},
		},
		popup: {
			'mentores-sugeridos': {
				titulo: 'Mentores sugeridos',
				cuerpo: 'Los mentores sugeridos son aquellos que han configurado áreas profesionales similares a tu perfil. No obstante, puedes ampliar o reducir la búsqueda como quieras para encontrar al mentor con el que deseas hacer matching.'
			},
			'busquedas-guardadas': {
				titulo: 'Búsquedas guardadas',
				cuerpo: 'Puedes guardar hasta 3 búsquedas. Se te mostrará en este apartado un aviso cuando haya un nuevo mentor/a coincidente con la configuración de búsqueda guardada. Estas búsquedas se borrarán cuando tengas una mentoría activa.'
			},
			'cambiar-dni': {
				titulo: 'Cambiar DNI/NIE o Pasaporte',
				cuerpo: 'Si tu DNI/NIE o Pasaporte no es correcto y requieres cambiarlo, por favor contacta con nosotros.'
			},
			'visible-otros': {
				titulo: 'Formar parte de la Red de Mentores de este programa',
				cuerpo: 'Su aceptación te permitirá estar visible en la Red de Mentores para otros mentores participantes, consultar sus perfiles profesionales, generar sinergias y fortalecer tus vínculos UC3M. Podrás habilitarla o deshabilitarla siempre que lo desees desde esta opción.'
			},
			'visible-otros-registro': {
				titulo: '',
				cuerpo: 'Su aceptación te permitirá consultar los perfiles profesionales de otros mentores Alumni UC3M, generar sinergias y fortalecer tus vínculos UC3M. Se podrá cambiar accediendo a tu área privada una vez te hayas registrado.'
			},
			'cambiar-genero': {
				titulo: 'Cambiar Género',
				cuerpo: 'Si tu género no es correcto y requieres cambiarlo, por favor contacta con nosotros.'
			},
			'cambiar-email-uc3m': {
				titulo: 'Cambiar Email UC3M',
				cuerpo: 'Si tu email no es correcto y requieres cambiarlo, por favor contacta con nosotros.'
			},
			'cambiar-alumno': {
				titulo: 'Egresado/a UC3M',
				cuerpo: 'Si el dato no es correcto y requieres cambiarlo, por favor contacta con nosotros.'
			},
			'experiencia-total' : {
				titulo: 'Experiencia profesional total',
				cuerpo: 'Indica el número total de años de experiencia profesional.'
			},
			'contrasena' : {
				titulo: 'Requisitos',
				cuerpo: 'La contraseña debe tener entre 8 y 30 caracteres.<br>Debe contener al menos una mayúscula, una minúscula, un carácter especial y un carácter numérico.<br>Como caracteres válidos sólo están permitidos: letras minúsculas (a-z) y mayúsculas (A-Z), números (0-9) y cualquiera de los siguientes caracteres especiales: ! ? # @ $ % & ( ) + - / * = [ ] { } _ . : ;.'
			},
			'ayuda-login' : {
				titulo: 'Login',
				cuerpo: 'El DNI debe escribirse completo, con los ceros iniciales y la letra final, sin espacios ni guiones. Ejemplo 00523821F.<br><br>El NIE (Número de Identificación de Extranjeros) debe escribirse con la letra inicial, todos los números y la letra final, sin espacios ni guiones. Ejemplo: X0523821F.<br><br>El Número de Pasaporte o Tarjeta de Residencia debe teclearse con todos los números y letras pero sin guiones ni espacios.'
			},
			'ayuda-nia' : {
				titulo: 'NIA',
				cuerpo: 'El NIA (número de Identificación de alumno) te identifica como parte del colectivo de la Universidad Carlos III de Madrid. Está formado por 9 dígitos en formato numérico. Ejemplos: 100xxxxxx o 300xxxxxx.'
			}
		}
	},
	en: {
	}
}
