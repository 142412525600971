<template>

    <div class="cover-popup row-center">
        <div class="popup">
            <h2 class="title-pop">¡Hola!</h2>
            <p>Aún no estas inscrito/a a ningún programa, por favor contacta con nosotros.</p>
            <router-link class="btn" @click.native="requestVisto" :to="$t('links.contacto')">Contactar</router-link>
        </div>
    </div>

</template>

<script>

    import {mapActions} from 'vuex';

    export default{
        name : 'popup-programas',
        methods : {
            ...mapActions(['showVistoMensaje']),
            requestVisto(){
                this.showVistoMensaje();
            }
        }
    }
</script>