<template>
    <div class="date-selector">
        <v-date-picker v-model="range" mode="date" :masks="masks" @dayclick="emit" is-range>
            <template v-slot="{ inputValue, inputEvents }">
                <input
                :value="(inputValue.start) ? inputValue.start+' - '+inputValue.end : 'Selecciona una fecha'"
                v-on="inputEvents.start"
                placeholder="Selecciona una fecha"
              />
            </template>
        </v-date-picker>
    </div>
</template>


<script>

    export default {
        name: 'datepicker',
        props: ['name', 'minpage', 'value'],
        data() {
            return {
                range: {
                    start: null,
                    end: null,
                },
                masks: {
                    input: 'DD/MM/YYYY',
                    data: 'DD/MM/YYYY'
                },
            }
        },
        methods: {
            emit() {
                this.$emit('input', this.range);
            }
        }
    }
</script>