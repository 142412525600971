import Vue from 'vue'
import Vuex from 'vuex'

import auth from './auth.js'
import alerts from './alerts.js'
import domains from './domains.js'
import perfil from './perfil.js'
import recursos from './recursos.js'
import programas from './programas.js'
import busquedamentores from './busquedamentores.js'
import mentorias from './mentorias.js'

import axios from 'axios';

Vue.use(Vuex)

let server = (location.hostname != 'gestionmentoring.uc3m.es') ? 'https://back-uc3m.dev.doubledot.es/' : 'https://gestionalumni.uc3m.es/' 
//server = 'http://localhost:8000';
export default new Vuex.Store({
  state: {
    language : 'es',
    server : server
  },
  getters : {
    getServer : state => state.server,
  },
  mutations: {
  },
  actions: {
		initApp : ({dispatch}) => {
			dispatch('requestUserInfo');
      dispatch('requestProfesional')
      dispatch('requestEntries')
      dispatch('requestProgramas');
      dispatch('requestOwnProgramas');
      dispatch('requestMentorias');
		},
    sendContacto({commit},data) {
      return new Promise((resolve) => {
        axios.post(`/api/contact/private/save`,data).then(response => {
          resolve(response.data.status);
          if(response.data.status){
            commit('setAlert',1013)
          }
        });
      });
    }
  },
  modules: {
    auth,
    alerts,
    domains,
    perfil,
    recursos,
    programas,
    mentorias,
    busquedamentores
  }
})
