<template>
    <div class="popup-info" @mouseleave="show = false">
        <span class="launcher" @mouseover="show = true">i</span>
        <div class="popup-info-body" v-show="show">
            <a @click="show = false" class="close">✕</a>
            <h2>{{$t(`popup['${data}'].titulo`)}}</h2>
            <p v-html="$t(`popup['${data}'].cuerpo`)"></p>
        </div>
    </div>
</template>

<script>
    export default{
        name : 'popup-info',
        props : ['data'],
        data : () => ({
            show : false
        })
    }
</script>