<template>
    <div class="home-mentor">

        <section class="encabezado -home">
            <h1>Área Mentees Alumni UC3M</h1>
        </section>

        <resumen-mentorias></resumen-mentorias>

        <recursos-mentor></recursos-mentor>

        
    </div>
</template>


<script>

    
    import resumenmentorias from '@/components/home/resumen-mentorias'
    import recursosmentor from '@/components/home/recursos-mentor'

    export default{
        name : 'home-mentor',
        components : {
            
            'resumen-mentorias' : resumenmentorias,
            'recursos-mentor' : recursosmentor
        }
    }
</script>