import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

import axios from 'axios';


const mentorias = {
    state: {
        mentorias: '',
    },
    getters: {
        getMentorias: state => state.mentorias,
        getMentoriasStatus: state => {
            let mentorias = {
                0: [],
                1: [],
                2: [],
                3: []
            };

            if (state.mentorias.length != 0) {
                state.mentorias.forEach(m => mentorias[m.status].push(m));
                mentorias[2] = mentorias[2].concat(mentorias[3]);
            }
            return mentorias;
        }

    },
    actions: {
        createMentoria({ commit, dispatch }, data) {

            return new Promise((resolve, reject) => {
                axios.post('/api/mentoring/create', data).then(result => {
                    if (result.data.status) {
                        
                        dispatch('requestMentorias')

                        let data = {
                            queries: null
                        }

                        dispatch('requestChangeUser', data).then(() => {
                            resolve(true);
                            commit('setAlert', 1010);
                        });

                    } else {
                        resolve(false)
                        commit('setAlert', `match-${result.data.error}`);
                    }

                }).catch(error => {
                    reject(error);
                });
            });
        },
        requestMentorias({ commit }) {

            axios.get('/api/mentoring/get').then(result => {
                commit('setMentorias', result.data);
            });

        },
        confirmarMentoria({ commit, dispatch }, id) {
            return new Promise((resolve) => {

                let data = {
                    status: 1
                }

                axios.post('/api/mentoring/update/' + id, data).then(result => {
                    if (result.data.status) {
                        resolve(true);
                        commit('setAlert', 1010);
                        dispatch('requestMentorias')
                    } else {
                        resolve(false);
                        commit('setAlert', `match-${result.data.error}`);
                    }
                });
            });

        },
        cancelarMentoria({ commit, dispatch }, data) {
            return new Promise((resolve) => {
                axios.post('/api/mentoring/update/' + data.id, data).then(result => {
                    if (result.data.status) {
                        resolve(true);
                        commit('setAlert', 1011);
                        dispatch('requestMentorias')
                    } else {
                        resolve(false);
                        commit('setAlert', `match-${result.data.error}`);
                    }
                });
            });

        },
        sendValoracion({ getters, commit, dispatch }, data) {
            return new Promise((resolve, reject) => {

                let url = {
                    "mentor": "/api/profesor/review/",
                    "mentee": "/api/mentee/review/"
                }

                axios.post(url[getters.getUserType] + data.id, data).then(result => {
                    dispatch('requestMentorias')
                    if (result.data.status) {
                        resolve(true);
                        commit('setAlert', 1014);
                    } else {
                        resolve(false);
                        commit('setAlert', 1020);
                    }


                }).catch(error => {
                    reject(error);
                    commit('setAlert', 1020);
                });
            });

        },
        getPrivateDoc: ({dispatch}, id) => {
            axios.get(`/api/mentee/title/${id}`, { responseType: 'arraybuffer' })
                .then(response => {
                    let r = {
                        response: response,
                    };
                    dispatch('DownloadDoc', r);
                });
        },
        DownloadDoc({ commit }, data) {
            var filename = '';
            var disposition = data.response.headers['content-disposition'];
            var type = data.response.headers['content-type'];
            if (disposition && disposition.indexOf('attachment') !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
            }

            commit('null')

            var blob = new Blob([data.response.data], { type: type });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            link.click()
        }
    },
    mutations: {
        setMentorias(state, data) {
            state.mentorias = data;
        }
    }
}

export default mentorias;
