<template>
    <div :show="!!code || !!alert" class="alert-container" :haslogout="$t(`alerts['${code}'].logout`) == 'true'">

        
        <div class="alert">
            <a class="close" @click="eraseAlertCode">✕</a>
            <template v-if="!!code">
                <h2>{{$t(`alerts['${code}'].titulo`)}}</h2>
                <p v-html="$t(`alerts['${code}'].mensaje`)"></p>
                <router-link class="btn" v-if="$t(`alerts['${code}'].logout`) == 'true'" @click.native="eraseAlertCode" :to="$t('links.logout')">Aceptar</router-link>
            </template>
            <template v-if="alert">
                <h2>¡Atención!</h2>
                <p :key="key" v-for="(e,key) in alert">{{e[0]}}</p>
            </template>
        </div>

    </div>
</template>

<script>

    import {mapGetters,mapActions} from 'vuex';
 
    export default{
        name : 'alert',
        computed : {
            ...mapGetters({
                code : 'getAlertCode',
                alert : 'getAlert'
            })
        },
        methods : {
            ...mapActions(['eraseAlertCode'])
        },

    }
</script>
