<template>
    <section class="recursos-home">
        <div class="recursos-container row-between">
            <section>
                <h2 class="subtitle">Últimos Recursos y Eventos</h2>
                <template v-if="entries && entries.length != 0">
                    <article class="recurso-el" :key="key" v-for="(n,key) in entries.slice(0,4)">
                        <h4 v-if="n.priority == 1">Destacado</h4>
                        <h2>{{(n.section == 1) ? 'Evento' : 'Recurso'}}. {{n.title}}</h2>
                        <p>{{n.header}}</p>
                        <p v-if="n.section != 1">{{n.created_at | parsedate}}</p>
                        <router-link :to="$t('links.recursos')+'/'+n.slug" class="enlace-cover"></router-link>
                    </article>
                    <div class="contenedor-boton">
                        <router-link :to="$t('links.recursos')" class="link-arrow">Ver todos</router-link>
                    </div>
                </template>
                <p v-else>Actualmente no hay recursos disponibles</p>
            </section>
            <section v-if="fullprogramas.length != 0">
                <h2 class="subtitle">Programas Activos</h2>
                
                <template v-if="programas && programas.length != 0">
                    <programa-el :data="programa" :key="key" v-for="(programa,key) in programas"></programa-el>
                    <div class="contenedor-boton">
                        <router-link :to="$t('links.programas')" class="link-arrow">Ver todos</router-link>
                    </div>
                </template>
                <p v-else>Actualmente no hay programas activos.</p>
            </section>
        </div>
    </section>
</template>


<script>

    import { mapGetters } from 'vuex';

    import moment from 'moment';

    import programa from '@/components/programa-home'


    export default {
        name: 'recursos-mentor',
        computed: {
            ...mapGetters({
                entries: 'getEntriesDestacados',
                fullprogramas  :'getProgramas'
            }),
            programas(){

                let programas;

                if(this.fullprogramas.length != 0){
                    programas = JSON.parse(JSON.stringify(this.fullprogramas)).filter(p => {
                        let init = new Date(p.start_date).setHours(0,0,0);
                        let date = new Date().setHours(0,0,0);
                        let end = new Date(p.end_date).setHours(0,0,0);
                        return (init <= date && end >= date);
                    })
                }

                return programas;
            }
        },
        components : {
            'programa-el' : programa
        },
        filters: {
            parsedate(value) {
                return moment(value).format("DD/MM/YYYY")
            }
        }
    }
</script>