<template>
    <section class="red-mentores" v-if="data && data.length != 0">
        <h2 class="subtitle">Red de Mentores</h2>
        <div class="container contenedor-mentores row-center">
            <article class="mentor" :key="key" :data="n" v-for="(n,key) in data.slice(0,4)">
                <div class="imagen" :style="(n.image_url) ? `background:url(${n.image_url}) center/cover no-repeat` : null"></div>
                <h2>{{n.name}} {{n.surname}} {{n.surname_2}} <a v-if="n.linkedin" target="_blank" :href="n.linkedin" class="linkedin"><i class="fa fa-linkedin"></i></a>
                </h2>
                <p>{{n.profesional_current}}</p>
                <p><strong>{{n.profesional_current_company}}</strong></p>
            </article>
        </div>

        <router-link v-if="usertype == 'mentee'" :to="$t('links.encuentramentor')" class="btn -skull-orange">Ver todos</router-link>
        <router-link v-if="usertype == 'mentor'" :to="$t('links.redmentores')" class="btn -skull-orange">Ver todos</router-link>

    </section>
</template>

<script>

    import {mapActions, mapGetters} from 'vuex';

    export default {
        name: "slider-mentores",
        mounted(){
            this.setData();
        },
        data() {
            return {
                data : ''
            }
        },
        methods: {
            ...mapActions(['requestMentores']),
            setData() {
                
                this.requestMentores(null).then(r => {
                    this.data = r;
                });
            }
        },
        computed : {
            ...mapGetters({
                usertype : 'getUserType'
            })
        }
    }
</script>