import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

import axios from 'axios';




const recursos = {
    state: {
        entries: []
    },
    getters: {
        getEntries: state => state.entries,
        getEntriesDestacados: state => state.entries,
        getRecursos: state => state.entries.filter(e => e.section == 0),
        getRecursosDestacados: state => state.entries.filter(e => (e.section == 0 && e.priority == 1)),
        getEventos: state => state.entries.filter(e => e.section == 1)
    },
    actions: {
        requestEntries: ({ commit,getters }) => {

            

            let data = {
                order: [
                    {
                        field: "created_at",
                        order: "desc",
                        current: true,
                    }
                ]
            }

            if(getters.getOwnProgramas && getters.getOwnProgramas.length != 0){

                let el = {
                    name : 'programs.name',
                    search : getters.getOwnProgramas.map(item => item.id),
                    objectFilter : true
                }

                data.columns = [el];
            }

            
            axios.post(`/api/entries`, data).then(response => {    
                commit('setEntries', response.data) 
            });

            
        },
        requestEntriesFiltered: ({ commit,getters }, filtro) => {

            return new Promise((resolve) => {
                
                let clean = ['type','category'];

                clean.forEach(e => {
                    filtro[e].search = filtro[e].search.map(item => Number(item[0]) )
                })

                filtro.programas.search = filtro.programas.search.map(item => item.id);

                if(filtro.programas.search.length == 0){
                    if(getters.getOwnProgramas && getters.getOwnProgramas.length != 0){
                        filtro.programas.search = getters.getOwnProgramas.map(item => item.id);
                    }
                }
                
                let data = {
                    columns: Object.values(filtro),
                    order: [
                        {
                            field: "priority",
                            order: "desc",
                            current: true,
                        },
                        {
                            field: "created_at",
                            order: "desc",
                            current: true,
                        }
                    ]
                }
                
                axios.post(`/api/entries`, data).then(response => {
                    resolve(response.data);
                    commit('null');
                });
            });

        },
        requestEntrie: ({ commit }, slug) => {
            return new Promise((resolve) => {
                axios.get(`/api/entries/${slug}`).then(response => {
                    resolve(response.data);
                    commit('null');
                });
            });
        },
    },
    mutations: {
        setEntries(state, data) {
            state.entries = data;
        },
        null() { }
    }
}

export default recursos;
