<template>
    <div class="home-mentor">

        <section class="encabezado -home">
            <h1>Área Mentores Alumni UC3M</h1>
        </section>

        <resumen-mentorias></resumen-mentorias>

        <recursos-mentor></recursos-mentor>

        <red-mentores></red-mentores>
        
    </div>
</template>


<script>

    import redmentores from '@/components/home/red-mentores'
    import resumenmentorias from '@/components/home/resumen-mentorias'
    import recursosmentor from '@/components/home/recursos-mentor'

    export default{
        name : 'home-mentor',
        components : {
            'red-mentores' : redmentores,
            'resumen-mentorias' : resumenmentorias,
            'recursos-mentor' : recursosmentor
        }
    }
</script>