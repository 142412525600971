<template>

    <footer>
        <div class="container">

            <div class="top row-start ais">

                <div class="info">
                    <img src="/img/logo-uc3m-alumni.png" alt="uc3m Alumni">
                    <p>Universidad Carlos III de Madrid - Campus de Getafe</p>
                    <p>Despacho 15.0.03 - Edificio López Aranguren</p>
                    <p><a href="tel:916248700">91 624 87 00</a></p>
                    <p>
                        <router-link :to="$t('links.contacto')" v-if="logged">Contáctanos</router-link>
                        <a href="https://mentoringalumni.uc3m.es/contacto" target="_blank" v-else>Contáctanos</a>
                    </p>
                </div>
                <div class="links">
                    <h3>Alumni UC3M</h3>
                    <a target="_blank" href="https://www.uc3m.es/alumni/formacion-programas">Formación</a>
                    <a target="_blank" href="https://www.uc3m.es/alumni/empleo-emprendimiento">Empleo</a>
                    <a target="_blank" href="https://www.uc3m.es/ss/Satellite/AntiguosAlumnos/es/TextoMixta/1371299373592/Colabora">Colabora</a>
                </div>
                <div class="links">
                    <h3>Actualidad</h3>
                    <a target="_blank" href="https://www.uc3m.es/ss/Satellite/AntiguosAlumnos/es/TextoDosColumnas/1371305284056/">Newsletter</a>
                    <a target="_blank" href="https://www.uc3m.es/alumni/nombramientos">Nombramientos</a>
                    <a target="_blank" href="https://www.uc3m.es/alumni/noticias">Noticias</a>
                </div>
                <div class="more-info">
                    <h4>¿Necesitas más información?</h4>
                    
                    <nav class="row-center rrss">
                        <a href="https://twitter.com/alumniUC3M" target="_blank"><i class="fa fa-twitter"></i></a>
                        <a href="https://www.facebook.com/alumniuc3m/" target="_blank"><i class="fa fa-facebook"></i></a>
                        <a href="https://www.linkedin.com/company/alumni-uc3m" target="_blank"><i class="fa fa-linkedin"></i></a>
                        <a href="https://www.instagram.com/alumni_uc3m/" target="_blank"><i class="fa fa-instagram"></i></a>
                        <a href="https://www.flickr.com/photos/aauc3m/albums" target="_blank"><i class="fa flickr"></i></a>
                    </nav>
                </div>

            </div>
            <div class="bottom row-between">

                

                <nav class="row-end">
                    <router-link target="_blank" :to="$t('links.avisolegal')">Aviso legal</router-link>
                    <router-link target="_blank" :to="$t('links.politicacookies')">Aviso de cookies</router-link>
                    <a target="_blank" href="https://www.uc3m.es/inicio/informacion-legal/politica-privacidad">Política de protección de datos de carácter personal</a>
                    
                </nav>


            </div>
        </div>
    </footer>

</template>



<script>

    import {mapGetters} from 'vuex';

    export default{
        name : 'footer',
        computed: {
            ...mapGetters({
                logged : 'getLogged'
            })
        }
    }
</script>