import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

const formularios = {
	state: {
		alertcode :null,
        alert : ''
	},
	getters: {
        getAlertCode: state => state.alertcode,
        getAlert : state => state.alert
	},
	actions: {
        eraseAlertCode:({commit}) => {
            commit('eraseAlert');
        },
        setAlert({commit}, code){
            commit('setAlert',code)
        },
        setCustomAlert({commit}, alert){
            commit('setCustomAlert',alert)
        }
	},
	mutations: {
        eraseAlert(state){
            state.alertcode = null;
            state.alert = null;
        },
        setAlert(state, code){
            state.alertcode = code;
        },
        setCustomAlert(state, alert){
            state.alert = alert;
        }
	}
}

export default formularios;
