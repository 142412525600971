import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

import axios from 'axios';



const academics = {
    state: {
        complete : {
            academics : false,
            profesional : false,
        },
        academics : [],
        profesional :null,
        needProfesional : false
    },
    getters: {
        getComplete : state => state.complete,
        getNeedProfesional : state => (state.profesional && state.profesional.length == 0)
    },
    actions: {
        saveGeneral: ({ commit, dispatch }, data) => {

            return new Promise((resolve, reject) => {

                //MODIFICACIONES PARA EL SERVIDOR
                let clean = ['ability', 'language','sector','area'];

                clean.forEach(e => {

        
                    if (Array.isArray(data[e])) {
                        let array = new Array();
                        data[e].forEach(v => {
                            array.push(v.id);
                        })
                        data[e] = array;
                    } else {
                        data[e] = Object.keys(data[e]);
                    }
                });

                

                axios.post('/api/general/user/save', data).then(result => {
                    if (result.data.status) {
                        dispatch('requestUserInfo');
                        commit('setAlert', 1007);
                        resolve(true);
                    }
                }).catch(error => {
                    reject(error);
                });
            });

        },
        requestAcademics: ({ commit }) => {

            return new Promise((resolve, reject) => {
                axios.get('/api/academics/get').then(result => {
                    resolve(result.data.items);
                    commit('setAcademics',result.data.items)
                    
                }).catch(error => {
                    reject(error);
                });
            });

        },
        removeAcademic: ({ commit,dispatch }, id) => {
            return new Promise((resolve, reject) => {
                axios.post(`/api/academics/remove/${id}`).then(result => {
                    if (result.data.status) {
                        commit('setAlert', 1003);
                        resolve(true);
                        dispatch('requestAcademics')
                    }
                }).catch(error => {
                    reject(error);
                });
            });
        },
        saveAcademic: ({ commit }, data) => {
            return new Promise((resolve, reject) => {
                axios.post(`/api/academics/save${(data.dataId) ? '/' + data.dataId : ''}`, data).then(result => {
                    if (result.data.status) {
                        commit('setAlert', 1004);
                        resolve(true);
                    }
                }).catch(error => {
                    reject(error);
                });
            });
        },
        requestProfesional: ({ commit }) => {

            return new Promise((resolve, reject) => {
                axios.get('/api/profesional/get').then(result => {
                    resolve(result.data.items);
                    commit('setProfesional',result.data.items)
                }).catch(error => {
                    reject(error);
                });
            });

        },
        removeProfesional: ({ commit, dispatch }, id) => {
            return new Promise((resolve, reject) => {
                axios.post(`/api/profesional/remove/${id}`).then(result => {
                    if (result.data.status) {
                        commit('setAlert', 1005);
                        commit('removecomplete','profesional');
                        dispatch('requestProfesional')
                        resolve(true);
                    }
                }).catch(error => {
                    reject(error);
                });
            });
        },
        saveProfesional: ({ commit }, data) => {
            return new Promise((resolve, reject) => {
                axios.post(`/api/profesional/save${(data.dataId) ? '/' + data.dataId : ''}`, data).then(result => {
                    if (result.data.status) {
                        commit('setAlert', 1006);
                        resolve(true);
                    }
                }).catch(error => {
                    reject(error);
                });
            });
        },
        requestChangeUser: ({ getters, dispatch }, data) => {

            
            return new Promise((resolve) => {
                let user = getters.getuser;
                
                for (const key in data) {
                    user[key] = data[key];
                }
                
                user.image = '';
                
                
                dispatch('saveGeneral', user).then(r => {
                    resolve(r);
                })
            });

        },
        editImagenPerfil: ({ getters, dispatch }, data) => {

            return new Promise((resolve) => {
                let user = JSON.parse(JSON.stringify(getters.getuser));
                user.image = data;
                dispatch('saveGeneral', user).then(r => {
                    resolve(r);
                })
            });

        },
        requestEraseActualImg: ({ getters, dispatch }) => {

            return new Promise((resolve) => {
                let user = JSON.parse(JSON.stringify(getters.getuser));
                user.image = 'delete_photo';
                dispatch('saveGeneral', user).then(r => {
                    resolve(r);
                })
            });

        }
    },
    mutations : {
        setAcademics(state,data){
            state.complete.academics = (data.length != 0);
            state.academics = data;
        },
        setProfesional(state,data){
            state.complete.profesional = (data.length != 0);
            state.profesional = data;
        },
        setCompleteUser(state,data){
            

            let mentoring = ['preferences_motivation','preferences_participation','area','sector','language','ability', 'observations_other_abilities'];
            let mentoringcomplete = true;

            mentoring.forEach(e => {
                if(mentoringcomplete){
                    mentoringcomplete = (data[e] != null && data[e].length != 0) ? true : false
                }
            });

            state.complete.mentoring = mentoringcomplete;


            let personal = ['email','country','birthday_date','name','phone','gender','dni'];
            let personalcomplete = true;

            personal.forEach(e => {
                if(personalcomplete){
                    personalcomplete = (data[e] != null) ? true : false
                }
            });

            state.complete.personal = personalcomplete;


            
            

        } 
    }
}

export default academics;
