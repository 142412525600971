import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

//IDIOMAS
import VueI18n from 'vue-i18n'
import messages from './translations';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: store.state.language, 
  messages,
});
//.IDIOMAS

/*AXIOS*/
import axios from 'axios'
import VueAxios from 'vue-axios'
axios.defaults.baseURL = store.state.server;
Vue.use(VueAxios, axios)
let token = window.localStorage.getItem('_token');
if (token) {
  axios.defaults.headers.common['authorization'] = `Bearer ${token}`;
}
/*AXIOS*/



import { ValidationProvider } from 'vee-validate';
import { ValidationObserver } from 'vee-validate';
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
import { extend } from 'vee-validate';
import {email,required,confirmed,min,size,mimes,numeric,regex } from 'vee-validate/dist/rules';
extend('email', {
  ...email,
  message: 'El email está mal formado'
});

extend('required', {
  ...required,
  message: 'Este campo es obligatorio'
});

extend('confirmed', {
  ...confirmed,
  message: 'Las contraseñas no son iguales'
});

extend('min', {
  ...min,
  message: 'Este campo no tiene la longitud mínima'
});

extend('size', {
  ...size,
  message: 'El tamaño máximo son 2MB'
});

extend('mimes', {
  ...mimes,
  message: 'El formato de imagen no está permitido'
});
extend('numeric', {
  ...numeric,
  message: 'El campo debe contener números'
});

extend('regex', {
  ...regex,
  message: 'La contraseña no cumple los requisitos mínimos'
});




import VCalendar from 'v-calendar';
Vue.use(VCalendar);

import datepicker from '@/components/own-datepicker';
Vue.component('own-datepicker', datepicker);

import yeardatepicker from '@/components/year-datepicker';
Vue.component('year-datepicker', yeardatepicker);

import datepickermulti from '@/components/datepicker-multi';
Vue.component('datepicker-multi', datepickermulti);

import iconeditar from '@/components/icons/editar';
Vue.component('icon-editar', iconeditar);

import icondelete from '@/components/icons/delete';
Vue.component('icon-delete', icondelete);

import popupinfo from '@/components/popup-info';
Vue.component('popup-info', popupinfo);

import Multiselect from 'vue-multiselect'

// register globally
Vue.component('multiselect', Multiselect)



new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
