<template>
    <div class="date-selector">
        <v-date-picker v-model="date" mode="date" :masks="masks" @dayclick="emit" :available-dates='{ start: new Date(minDate), end: null }'>
            <template v-slot="{ inputValue, inputEvents }">
              <input
                :value="inputValue"
                v-on="inputEvents"
                type="text"
                class="calendar"
                :fill="!!date"
                :name="name"
                placeholder="Selecciona una fecha"
                @blur="emit"
              />
            </template>
          </v-date-picker>
    </div>
</template>


<script>

    import moment from 'moment';

    export default {
        name: 'datepicker',
        props : ['name','minpage','value','minDate'],
        mounted(){
            if(this.value){
                this.date = new Date(this.value);
            }
        },
        data() {
            return {
                date : '',
                masks: {
                    input: 'DD/MM/YYYY',
                    data: 'DD/MM/YYYY'
                },
            }
        },
        methods: {
            emit() {
                console.log(this.date);
                this.$emit('input', moment(this.date).format("YYYY-MM-DD"));
            }
        }
    }
</script>